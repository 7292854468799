import React from "react"
import { graphql } from "gatsby"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import Header from "../components/Header"
import PageWrapper from "../PageWrapper"

const LegalIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Header
        component={
          <div className="header-hero header-hero--legal bg_cover d-lg-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="header-hero-content">
                    {/* <!--====== FIRST SECTION START======--> */}

                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div className="about-title">
                          <h1 className="legal--welcome text-center mb-5">
                            Legal Notice
                          </h1>
                          <div className="title">
                            <h5 className="legal title">Registered Office</h5>
                          </div>

                          <div className="pt-10 mb-20">
                            <p>Lexiqa Limited</p>
                            <p>KGCA, Suite 2</p>
                            <p>Wesley Chambers, Queens Road</p>
                            <p>Aldershot, Hampshire</p>
                            <p>GU11 3JD</p>
                            <p>United Kingdom</p>
                          </div>

                          <div className="title">
                            <h5 className="legal title">Email</h5>
                          </div>
                          <div className="legal pt-10 mb-20">
                            <p>info (at) lexiqa.net</p>
                          </div>
                          <div className="title">
                            <h5 className="legal title">VAT number</h5>
                          </div>
                          <div className="legal pt-10 mb-20">
                            <p>GB311174645</p>
                            <span className="pt-30">
                              Lexiqa Limited incorporated in England and Wales
                              under company number 10021762
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--======FIRST SECTION END======--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        title={siteTitle}
      />
      <SEO title="Legal Notice" />

      <Footer />
    </PageWrapper>
  )
}

export default LegalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
